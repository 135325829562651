import mirrorApi from "../api/mirror_trade_api";
import { getAuthToken } from "./auth";

export const getBillingInfo = async (data) => {
  try {
    return await mirrorApi.get(`billing-notice`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } catch (error) {
    console.log("🚀 ~ getBillingInfo ~ error:", error);
  }
};
