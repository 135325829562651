import { createSlice } from "@reduxjs/toolkit";

const billingSlice = createSlice({
    name: "billing",
    initialState: {
        billingInfo:{}
    },
    reducers: {
        setBillingInfo: (state, action) => {
            state.billingInfo = action.payload;
        },
    },
});

export const { setBillingInfo} = billingSlice.actions;
export default billingSlice.reducer;
