import { Box } from "@mui/material";
import ForgotPassword from "components/Auth/ForgotPassword";
import ForgotPasswordSendingMail from "components/Auth/ForgotPasswordSendingMail";
import Login from "components/Auth/Login";
import Registration from "components/Auth/Registration";
import UnverifiedEmailLogin from "components/Auth/UnverifiedEmailLogin";
import VerifyEmailOtp from "components/Auth/VerifyEmailOtp";
import VerifyOtp from "components/Auth/VerifyOtp";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import backgroundImage from "../../assets/auth/trading-auth-1.png";
import logo from "../../assets/img/image-2023-11-21t10-07-25-128z-1.png";

const Auth = () => {
  const authType = useSelector((state) => state.navigate.type);
  const [otpCode, setOtpCode] = useState("");
  const [email, setEmail] = useState("");
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
      }}
      className="w-full h-screen flex justify-center items-center"
    >
      <Box sx={{ border: "1px solid rgba(65, 255, 200, 0.31)" }} className="w-[95%] md:w-[500px] bg-[#0F3529] rounded-md shadow-2xl">
        <Box className="text-center mt-2">
          <img className="w-[130px] h-[58px]" src={logo} alt="logo" />
        </Box>
        {authType === "login" ? (
          <Box className="text-[var(--color-white-1)] font-[Poppins] font-bold text-center text-xl mb-5">
            Welcome back! <br /> <span className="font-normal text-lg">Please login to mirror trade.</span>
          </Box>
        ) : authType === "registration" ? (
          <Box className="text-[var(--color-white-1)] font-[Poppins] font-bold text-center text-xl mb-5">Please registration to mirror trade.</Box>
        ) : authType === "forgot_password_send_mail" ? (
          <Box className="text-[var(--color-white-1)] font-[Poppins] font-bold text-center text-xl mb-5">Please put your email in this field.</Box>
        ) : authType === "Otp_verify" ? (
          <Box className="text-[var(--color-white-1)] font-[Poppins] font-bold text-center text-base mb-5">Check your email we send verification code.</Box>
        ) : authType === "verifyEmailOtp" ? (
          <Box className="text-[var(--color-white-1)] font-[Poppins] font-bold text-center text-base mb-5">Check your email we send verification code.</Box>
        ) : authType === "unverifiedEmail" ? (
          <Box className="text-[var(--color-white-1)] font-[Poppins] font-bold text-center text-base mb-5">Enter your email with which you registered.</Box>
        ) : (
          ""
        )}
        {authType === "login" && <Login />}
        {authType === "verifyEmailOtp" && <VerifyEmailOtp />}
        {authType === "unverifiedEmail" && <UnverifiedEmailLogin />}
        {authType === "forgot_password_send_mail" && <ForgotPasswordSendingMail {...{ setEmail }} />}
        {authType === "Otp_verify" && <VerifyOtp {...{ setOtpCode, email }} />}
        {authType === "forgot_password" && <ForgotPassword {...{ otpCode, email }} />}
        {authType === "registration" && <Registration />}
      </Box>
    </Box>
  );
};

export default memo(Auth);
