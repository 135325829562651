import { Alert, Box, useTheme } from "@mui/material";
import moment from "moment"; // Importing moment.js
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Billing = memo(() => {
  const theme = useTheme();
  const billingInfo = useSelector((state) => state.billing.billingInfo);
  const [countdown, setCountdown] = useState("");


  useEffect(() => {
    if (billingInfo?.start_date && billingInfo?.end_date) {
      const calculateCountdown = () => {
        const now = moment();
        const end = moment(billingInfo.end_date);
        const diff = end.diff(now);

        if (diff <= 0) {
          setCountdown("Subscription ended");
          return;
        }

        const duration = moment.duration(diff);
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      };

      // Calculate countdown immediately
      calculateCountdown();

      // Update countdown every second
      const intervalId = setInterval(calculateCountdown, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [billingInfo]);

  return (
    <Box className="p-2">
      <Alert
        sx={{
          color:
            theme.palette.mode === "dark"
              ? "var(--color-white-1)"
              : "var(--color-black-1)",
        }}
        variant="filled"
        severity="warning"
        action={<Box>{countdown}</Box>}
      >
        Please be aware that your Mirror Trade subscription is due to end on{" "}
        {billingInfo?.end_date}. To prevent any service interruptions, please
        proceed with re-subscription.
      </Alert>
    </Box>
  );
});

export default Billing;
